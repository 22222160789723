import React, { Component } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import ReadAloud from "components/Question/Speaking/ReadAloud.jsx";
import DescribeImage from "components/Question/Speaking/DescribeImageNew.jsx";
import RepeatSentece from "components/Question/Speaking/RepeatSentece.jsx";
import RetellLec from "components/Question/Speaking/RetellLec.jsx";
import ShortQue from "components/Question/Speaking/ShortQue.jsx";
import Summary from "components/Question/Writting/Summary.jsx";
import Essay from "components/Question/Writting/Essay.jsx";
import SingleAnswer from "components/Question/Reading/SingleAnswer.jsx";
import MultipleAnswer from "components/Question/Reading/MultipleAnswer.jsx";
import Reorder from "components/Question/Reading/Reorder1.jsx";
import ReadingBlank from "components/Question/Reading/ReadingBlankNew.jsx";
import WrittingBlank from "components/Question/Reading/WrittingBlank.jsx";
import Summer from "components/Question/Listening/Summer.jsx";
import McqSingle from "components/Question/Listening/McqSingle.jsx";
import McqMulti from "components/Question/Listening/McqMulti.jsx";
import ListeningBlank from "components/Question/Listening/ListeningBlank.jsx";
import MissingWord from "components/Question/Listening/MissingWord.jsx";
import CorrectSummary from "components/Question/Listening/CorrectSummary.jsx";

import Dictation from "components/Question/Listening/Dictation.jsx";
import Highlight from "components/Question/Listening/Highlight.jsx";
import ShowHistory from "views/ShowHistory.jsx";
import ShowHistoryData from "views/ShowHistoryData.jsx";
import ChromeCheck from "views/ChromeCheck.jsx";
import QuestionComment from "views/QuestionComment.jsx";
import UserNotes from "views/UserNotes.jsx";
import ReactHtmlParser from "react-html-parser";
import API, { s3base } from "api.js";
import { store } from "store/index";
import { setAlert } from "action/index.js";
import { setUser } from "action/index.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";

import Timer from "components/Timer/Timer.jsx";
import CheckInternetTimer from "components/Timer/CheckInternetTimer.jsx";
import KeepAlive from "views/KeepAlive.jsx";
import WordOptionsPopup from "./WordOptionsPopup";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};
class Practice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: [],
      currentQuestion: 0, //parseInt(this.props.match.params.index),
      isRecording: false,
      blobURL: "",
      selectedWord: '',
      wordOption: false,
      error: "",
      history: [],
      board: [],
      historyData: [],
      history_flag: false,
      isBlocked: false,
      is_answer: false,
      is_result: false,
      resultObj: {
        text_answer: "",
        length: 0,
        type: this.props.match.params.id,
        answer: "",
        script: "",
        q_ans: "",
        correct: "",
        strategy: 1,
      },
      resultData: "",
      process: false,
      testModal: false,
      textAr: false,
      comment_process: false,
      modal: false,
      switched: false,
      reportText: "",
      history_process: false,
      historyMore: false,
      boardMore: false,
      restart: false,
      type: this.props.match.params.id,
      prediction: this.props.match.params.type,
      aType: this.props.match.params.aType,
      mark: this.props.match.params.mark,
      atmptd: this.props.match.params.attempted,
      complexity: this.props.match.params.complexity,
      submitFlag: false,
      attempted: 0,
      intervalId1: null,
      secondCount1: 0,
      note_flag: false,
      board_process: false,
      all_ok: true,
      hideNav: false,
      user: store.getState().user,
      isSubscribed:
        store.getState().user.active_subscription.length > 0 ? true : false,
      viewVideo: false,
      viewHindiVideo: false,
      videoModal: false,
      videoModalState: false,
      videoHindiModal: false,
      videoHindiModalState: false,
      videoUrl: "",
      videoHindiUrl: "",
      attemptedQuestions: [],
      isAttempted: false,
      orderby: localStorage.getItem("orderby")
        ? localStorage.getItem("orderby")
        : "desc",
      isSubscribedCheck: store.getState().subscribed
        ? store.getState().subscribed
        : "false",
      total_questions: 0,
      open_ques: 1,
      search_string: "",
      stopTimer: false,
      newQuestion: false,
      freeze: true,
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  setIntervalId = (interval_id) => {
    this.setState({ intervalId1: interval_id });
  };

  setSecondCount = (seconds) => {
    console.log("hello");
    this.setState({ secondCount1: seconds });
  };

  async componentDidMount() {
    let localStorage_orderby = localStorage.getItem("orderby");
    if (!localStorage_orderby) {
      localStorage.setItem("orderby", "desc");
    }

    let urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get("view");
    let hideNav = parseInt(myParam) === 1 ? true : false;
    let open_ques = parseInt(urlParams.get("page"))
      ? parseInt(urlParams.get("page"))
      : 0;
    let q_id = this.props.match.params.index;

    // this.setState({ hideNav, open_ques });

    let { type, prediction, aType, mark, atmptd, complexity, orderby } =
      this.state;

    const ques = await this.loadQuestion(open_ques, q_id);
    if (ques.status === 200) {
      // let intervalId1 = setInterval(this.timer1, 1000);

      let question_data = ques.data.data[0];

      this.setState(
        {
          hideNav,
          open_ques,
          questionData: ques.data.data,
          // tag: (data.data.data[currentQuestion].tag.length >0) ? data.data.data[currentQuestion].tag[0].tag : "grey",
          tag: question_data.tag.length > 0 ? question_data.tag[0].tag : "grey",
          // intervalId1: intervalId1,
          currentQuestion: open_ques,
          total_questions: ques.data.total,
          testCount: ques.data.data[0].tested,
          // testCount: data.data.data[this.state.currentQuestion].tested.length
        },
        () => {
          let { currentQuestion, questionData, user } = this.state;
          let { video, hindi_video, created_at } = questionData[0];
          if (ques.data.total !== 0 && open_ques > 0) {
            document.querySelector(".selectPage").value = currentQuestion;
          }

          const pastTime = new Date(created_at);
          const now = new Date();
          const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
          const timeDiffInMs = now.getTime() - pastTime.getTime();

          // const attempted_questions = this.state.user.user_attempted_questions.attempted_questions;

          let attempted_questions = [];
          if (
            user.user_attempted_questions !== null &&
            user.user_attempted_questions !== undefined
          ) {
            attempted_questions =
              user.user_attempted_questions.attempted_questions;
          }
          const obj =
            attempted_questions.length > 0
              ? JSON.parse(attempted_questions)
              : attempted_questions;
          const questions_id = obj.questions_id;

          this.setState(
            {
              videoUrl: video !== null ? video : null,
              viewVideo: video !== null ? true : false,
              videoHindiUrl: hindi_video !== null ? hindi_video : null,
              viewHindiVideo: hindi_video !== null ? true : false,
              thirtyDaysInMs,
              timeDiffInMs,
              attemptedQuestions: questions_id,
            },
            () => {
              this.loadAttemptedQuestiondata(currentQuestion);
            }
          );
        }
      );
    } else {
      store.dispatch(
        setAlert({
          flag: true,
          type: 2,
          message: "Something went wrong. Unable to load question.",
        })
      );
    }
  }
  wordOptionPopup = (status, word) => {
    this.setState({
      wordOption: status,
      selectedWord: word
    });
  };
  loadQuestiondata = () => {
    API({
      method: "GET",
      url: "question/",
    })
      .then((data) => {
        this.setState({ questionData: data.data.data });
      })
      .catch(() => {});
  };

  loadAttemptedQuestiondata = (currentQuestionId) => {
    const { user_attempted_questions } = store.getState().user;
    let attemptedQuestions = [];

    if (
      user_attempted_questions !== null &&
      user_attempted_questions !== undefined
    ) {
      attemptedQuestions = user_attempted_questions.attempted_questions;
    }
    let question_id = this.state.questionData[0].id;
    question_id = question_id.toString();
    let checkquestionid = attemptedQuestions.includes(question_id);
    if (checkquestionid) {
      this.setState({ isAttempted: true });
    } else {
      this.setState({ isAttempted: false });
    }
  };

  componentWillUnmount = () => {
    if (this.state.intervalId1 !== null) {
      clearInterval(this.state.intervalId1);
    }
  };

  changeReportText = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  submitReport = () => {
    if (this.state.comment === "") {
      store.dispatch(
        setAlert({
          flag: true,
          type: 2,
          message: "Message cant't be empty",
        })
      );
      return "";
    }

    let bodyFormData = new FormData();
    bodyFormData.set("text", this.state.reportText);
    bodyFormData.set(
      "question_id",
      this.state.questionData[this.state.currentQuestion].id
    );

    API({
      method: "POST",
      data: bodyFormData,
      url: "report/question",
    })
      .then((data) => {
        if (data.status === 200) {
          this.setState({
            reportText: "",
            modal: false,
          });
          store.dispatch(
            setAlert({ flag: true, type: 1, message: data.data.message })
          );
        }
      })
      .catch(() => {});
  };

  //................>>>.....we have to use this
  // nextButton = async () => {
  //   this.setState({ is_answer: false }, async () => {

  //     let { currentQuestion } = this.state;
  //     let new_ques = await this.loadQuestion(currentQuestion + 1);
  //     if (new_ques.status === 200) {

  //       let question_data = new_ques.data.data[0];
  //       let { id, tag, tested, video, hindi_video, created_at,attempted,type
  //       } = question_data;

  //       let now = new Date();
  //       let pastTime = new Date(created_at);
  //       let thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  //       let timeDiffInMs = now.getTime() - pastTime.getTime();

  //       this.setState({
  //         comment: [],
  //         board: [],
  //         history: [],
  //         process: false,
  //         currentQuestion: currentQuestion + 1,
  //         tag: (tag.length > 0) ? tag[0].tag : "grey",
  //         is_answer: false,
  //         is_result: false,
  //         history_flag: false,
  //         comment_flag: false,
  //         secondCount1: 0,
  //         testCount: tested,
  //         // testCount: this.state.questionData[this.state.currentQuestion+1].tested.length,
  //         board_flag: false,
  //         history_flag: false,
  //         comment_flag: false,
  //         note_flag: false,
  //         viewVideo: video !== null ? true : false,
  //         viewHindiVideo: hindi_video !== null ? true : false,
  //         videoUrl: video !== null ? video : null,
  //         videoHindiUrl: hindi_video !== null ? hindi_video : null,
  //         thirtyDaysInMs,
  //         timeDiffInMs,

  //         questionData: new_ques.data.data,

  //         newQuestion: true
  //       }, () => {
  //         this.loadAttemptedQuestiondata(currentQuestion);
  //       });
  //       let urlParams = new URLSearchParams(window.location.search);
  //       let search_string = urlParams.get('search') === '' || urlParams.get('search') === null ? '' : urlParams.get('search');
  //       // Update URL with the new question ID
  //       // this.props.history.push(`/admin/practice/0/${this.props.match.params.aType}/${id}/${this.props.match.params.aType}/${this.state.mark}/${this.props.match.params.attempted}/${this.state.complexity}?page=${currentQuestion + 1}&search=`);
  //       this.props.history.push(`/admin/practice/0/${this.state.type}/${id}/${this.props.match.params.aType}/${this.state.mark}/${this.props.match.params.attempted}/${this.state.complexity}?page=${currentQuestion + 1}&search=${search_string}`);
  //       //http://www.localhost:3000/admin/practice/0/1/12243/1/all/0/all?page=2&search=
  //       document.querySelector('.selectPage').value = currentQuestion + 1;
  //       this.forceUpdate();
  //     } else {
  //       store.dispatch(setAlert({
  //         'flag': true,
  //         'type': 2,
  //         'message': 'Something went wrong. Unable to load question.'
  //       }));
  //     }
  //   });
  // }

  nextButton = async () => {
    this.setState({ is_answer: false }, async () => {
      let { currentQuestion } = this.state;
      let new_ques = await this.loadQuestion(currentQuestion + 1);
      if (new_ques.status === 200) {
        let question_data = new_ques.data.data[0];
        let { tag, tested, video, hindi_video, created_at } = question_data;

        let now = new Date();
        let pastTime = new Date(created_at);
        let thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        let timeDiffInMs = now.getTime() - pastTime.getTime();

        this.setState(
          {
            comment: [],
            board: [],
            history: [],
            process: false,
            currentQuestion: currentQuestion + 1,
            tag: tag.length > 0 ? tag[0].tag : "grey",
            is_answer: false,
            is_result: false,
            history_flag: false,
            comment_flag: false,
            secondCount1: 0,
            testCount: tested,
            // testCount: this.state.questionData[this.state.currentQuestion+1].tested.length,
            board_flag: false,
            history_flag: false,
            comment_flag: false,
            note_flag: false,
            viewVideo: video !== null ? true : false,
            viewHindiVideo: hindi_video !== null ? true : false,
            videoUrl: video !== null ? video : null,
            videoHindiUrl: hindi_video !== null ? hindi_video : null,
            thirtyDaysInMs,
            timeDiffInMs,

            questionData: new_ques.data.data,

            newQuestion: true,
          },
          () => {
            this.loadAttemptedQuestiondata(currentQuestion);
          }
        );

        document.querySelector(".selectPage").value = currentQuestion + 1;
        this.forceUpdate();
      } else {
        store.dispatch(
          setAlert({
            flag: true,
            type: 2,
            message: "Something went wrong. Unable to load question.",
          })
        );
      }
    });
  };

  //................>>>.....we have to use this
  // prevButton = async () => {
  //   this.setState({ is_answer: false }, async () => {

  //     let { currentQuestion } = this.state;
  //     let new_ques = await this.loadQuestion(currentQuestion - 1);
  //     if (new_ques.status === 200) {

  //       let question_data = new_ques.data.data[0];
  //       let { id, tag, tested, video, hindi_video, created_at, attempted,type } = question_data;

  //       let now = new Date();
  //       let pastTime = new Date(created_at);
  //       let thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  //       let timeDiffInMs = now.getTime() - pastTime.getTime();

  //       this.setState({
  //         comment: [],
  //         board: [],
  //         history: [],
  //         process: false,
  //         currentQuestion: currentQuestion - 1,
  //         tag: (tag.length > 0) ? tag[0].tag : "grey",
  //         is_answer: false,
  //         is_result: false,
  //         history_flag: false,
  //         comment_flag: false,
  //         secondCount1: 0,
  //         testCount: tested,
  //         board_flag: false,
  //         history_flag: false,
  //         comment_flag: false,
  //         note_flag: false,
  //         viewVideo: video !== null ? true : false,
  //         viewHindiVideo: hindi_video !== null ? true : false,
  //         videoUrl: video !== null ? video : null,
  //         videoHindiUrl: hindi_video !== null ? hindi_video : null,
  //         thirtyDaysInMs,
  //         timeDiffInMs,

  //         questionData: new_ques.data.data,

  //         newQuestion: true
  //       }, () => {
  //         this.loadAttemptedQuestiondata(currentQuestion);
  //       });
  //       let urlParams = new URLSearchParams(window.location.search);
  //       let search_string = urlParams.get('search') === '' || urlParams.get('search') === null ? '' : urlParams.get('search');
  //       // Update URL with the new question ID
  //       this.props.history.push(`/admin/practice/0/${this.state.type}/${id}/${this.props.match.params.aType}/${this.state.mark}/${this.props.match.params.attempted}/${this.state.complexity}?page=${currentQuestion - 1}&search=${search_string}`);
  //       document.querySelector('.selectPage').value = currentQuestion - 1;
  //       this.forceUpdate();
  //     } else {
  //       store.dispatch(setAlert({
  //         'flag': true,
  //         'type': 2,
  //         'message': 'Something went wrong. Unable to load question.'
  //       }));
  //     }
  //   });
  // }

  prevButton = async () => {
    let { currentQuestion } = this.state;
    let new_ques = await this.loadQuestion(currentQuestion - 1);

    if (new_ques.status === 200) {
      let question_data = new_ques.data.data[0];
      let { tag, tested, video, hindi_video, created_at } = question_data;

      let pastTime = new Date(created_at);
      let now = new Date();
      let thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      let timeDiffInMs = now.getTime() - pastTime.getTime();

      this.setState(
        {
          comment: [],
          board: [],
          history: [],
          process: false,
          tag: tag.length > 0 ? tag[0].tag : "grey",
          currentQuestion: currentQuestion - 1,
          is_answer: false,
          is_result: false,
          history_flag: false,
          comment_flag: false,
          secondCount1: 0,
          testCount: tested,
          // testCount: this.state.questionData[this.state.currentQuestion-1].tested.length,
          board_flag: false,
          history_flag: false,
          comment_flag: false,
          note_flag: false,
          viewVideo: video !== null ? true : false,
          videoUrl: video !== null ? video : null,
          viewHindiVideo: hindi_video !== null ? true : false,
          videoHindiUrl: hindi_video !== null ? hindi_video : null,
          thirtyDaysInMs,
          timeDiffInMs,

          questionData: new_ques.data.data,

          newQuestion: true,
        },
        () => {
          this.loadAttemptedQuestiondata(this.state.currentQuestion - 1);
        }
      );
      document.querySelector(".selectPage").value = currentQuestion - 1;
      this.forceUpdate();
    } else {
      store.dispatch(
        setAlert({
          flag: true,
          type: 2,
          message: "Something went wrong. Unable to load question.",
        })
      );
    }
  };

  showModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  submitResponse = (data, f = false) => {
    let qdata = this.state.questionData[0];
    let obj = {
      data: data.data,
      text_answer: data.answer,
      length: data.length,
      type: qdata.subcategory_id,
      id: qdata.id,
      answer: qdata.question,
      script: qdata.audio_script,
      q_ans: qdata.answer,
      html: qdata.html,
      correct: data.correct,
      lang: data.lang,
      duration: data.duration,
      audio_text: data.audio_text,
      strategy: this.state.resultObj.strategy,
    };
    this.setState({
      resultObj: obj,
      submitFlag: f,
    });
  };

  loadQuestion = async (ques, q_id = null) => {
    let localStorage_orderby = localStorage.getItem("orderby");
    if (!localStorage_orderby) {
      localStorage.setItem("orderby", "desc");
    }

    let urlParams = new URLSearchParams(window.location.search);
    let hideNav = parseInt(urlParams.get("view")) === 1 ? true : false;

    let { type, prediction, aType, mark, atmptd, complexity, orderby } =
      this.state;
    let search_string =
      urlParams.get("search") === "" || urlParams.get("search") === null
        ? ""
        : urlParams.get("search");

    return await API({
      method: "GET",
      url: `question/${type}?prediction=${prediction}&type=${aType}&mark=${mark}&attempted=${atmptd}&complexity=${complexity}&orderby=${orderby}&practice=${!hideNav}&open_ques=${ques}&qid=${q_id}&search=${search_string}`,
    });
  };

  //................>>>.....we have to use this
  // changeSelectPagination = async (e) => {
  //   let selectedQuestion = parseInt(e.target.value);

  //   let new_ques = await this.loadQuestion(selectedQuestion);
  //   if (new_ques.status === 200) {
  //     let question_data = new_ques.data.data[0];

  //     console.log('....................question_data',question_data)

  //     let checkifSelectPaghasvideo = question_data.video;
  //     let checkifSelectPaghashindivideo = question_data.hindi_video;

  //     var ques_created_at = question_data.created_at;
  //     var pastTime = new Date(ques_created_at);
  //     var now = new Date();
  //     var thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  //     var timeDiffInMs = now.getTime() - pastTime.getTime();

  //     this.setState({
  //       comment: [],
  //       board: [],
  //       history: [],
  //       process: false,
  //       currentQuestion: selectedQuestion,
  //       tag: (question_data.tag.length > 0) ? question_data.tag[0].tag : "grey",
  //       is_answer: false,
  //       is_result: false,
  //       history_flag: false,
  //       comment_flag: false,
  //       note_flag: false,
  //       secondCount1: 0,
  //       viewVideo: checkifSelectPaghasvideo !== null ? true : false,
  //       videoUrl: checkifSelectPaghasvideo !== null ? checkifSelectPaghasvideo : null,
  //       viewHindiVideo: checkifSelectPaghashindivideo !== null ? true : false,
  //       videoHindiUrl: checkifSelectPaghashindivideo !== null ? checkifSelectPaghashindivideo : null,
  //       thirtyDaysInMs: thirtyDaysInMs,
  //       timeDiffInMs: timeDiffInMs,

  //       questionData: new_ques.data.data,

  //       newQuestion: true
  //     }, () => {
  //       this.loadAttemptedQuestiondata(selectedQuestion);
  //     });
  //     let urlParams = new URLSearchParams(window.location.search);
  //     let search_string = urlParams.get('search') === '' || urlParams.get('search') === null ? '' : urlParams.get('search');
  //     // Update URL with the new question ID
  //     this.props.history.push(`/admin/practice/0/${this.state.type}/${question_data.id}/${this.props.match.params.aType}/${this.state.mark}/${this.props.match.params.attempted}/${this.state.complexity}?page=${selectedQuestion}&search=${search_string}`);
  //     this.forceUpdate();
  //   } else {
  //     store.dispatch(setAlert({
  //       'flag': true,
  //       'type': 2,
  //       'message': 'Something went wrong. Unable to load question.'
  //     }));
  //   }
  // }

  changeSelectPagination = async (e) => {
    let selectedQuestion = parseInt(e.target.value);

    let new_ques = await this.loadQuestion(selectedQuestion);
    if (new_ques.status === 200) {
      let question_data = new_ques.data.data[0];
      let checkifSelectPaghasvideo = question_data.video;
      let checkifSelectPaghashindivideo = question_data.hindi_video;

      var ques_created_at = question_data.created_at;
      var pastTime = new Date(ques_created_at);
      var now = new Date();
      var thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      var timeDiffInMs = now.getTime() - pastTime.getTime();

      this.setState(
        {
          comment: [],
          board: [],
          history: [],
          process: false,
          currentQuestion: selectedQuestion,
          tag: question_data.tag.length > 0 ? question_data.tag[0].tag : "grey",
          is_answer: false,
          is_result: false,
          history_flag: false,
          comment_flag: false,
          note_flag: false,
          secondCount1: 0,
          viewVideo: checkifSelectPaghasvideo !== null ? true : false,
          videoUrl:
            checkifSelectPaghasvideo !== null ? checkifSelectPaghasvideo : null,
          viewHindiVideo: checkifSelectPaghashindivideo !== null ? true : false,
          videoHindiUrl:
            checkifSelectPaghashindivideo !== null
              ? checkifSelectPaghashindivideo
              : null,
          thirtyDaysInMs: thirtyDaysInMs,
          timeDiffInMs: timeDiffInMs,

          questionData: new_ques.data.data,

          newQuestion: true,
        },
        () => {
          this.loadAttemptedQuestiondata(selectedQuestion);
        }
      );

      this.forceUpdate();
    } else {
      store.dispatch(
        setAlert({
          flag: true,
          type: 2,
          message: "Something went wrong. Unable to load question.",
        })
      );
    }
  };

  showResponse = () => {
    this.setState(
      {
        is_answer: true,
        resultData: "",
        process: true,
        stopTimer: true,
      },
      () => {
        const sub_id = parseInt(this.state.questionData[0].subcategory_id);
        if ([1, 2, 3, 4, 5].includes(sub_id)) {
          var bodyFormData = new FormData();
          bodyFormData.set("text", this.state.resultObj.answer);
          bodyFormData.set("text_answer", this.state.resultObj.text_answer);
          bodyFormData.set("answer", this.state.resultObj.ans);
          bodyFormData.set("q_ans", this.state.resultObj.q_ans);
          bodyFormData.set("length", this.state.resultObj.length);
          bodyFormData.set("file", this.state.resultObj.data);
          bodyFormData.set("duration", this.state.resultObj.duration);
          bodyFormData.set("type", this.state.resultObj.type);
          bodyFormData.set("script", this.state.resultObj.script);
          bodyFormData.set("id", this.state.resultObj.id);
          bodyFormData.set("lang", this.state.resultObj.lang);
          bodyFormData.set("practice", "1");
          bodyFormData.set("audio_text", this.state.resultObj.audio_text);

          // Add strategy only for read-aloud.
          if (sub_id === 1) {
            bodyFormData.set("strategy", this.state.resultObj.strategy);
          }

          API({
            method: "POST",
            url: "check/answer1",
            data: bodyFormData,
          })
            .then((data) => {
              if (data.data.user) {
                store.dispatch(setUser(data.data.user));
                this.loadAttemptedQuestiondata(this.state.currentQuestion);
              }
              if (this.state.questionData[0].subcategory_id === 5) {
                this.setState({
                  user: data.data.user,
                  historyData: data.data.data.score_data,
                  process: false,
                  resultData: `Content Score : ${data.data.data.content} \n Your Response: ${data.data.data.transcript}`,
                });
                console.log("................", data.data.user);
              } else {
                console.log('this is score data from new Readaloud',data.data.data.score_data)
                this.setState({
                  user: data.data.user,
                  historyData: data.data.data.score_data,
                  process: false,
                  resultData: `Content Score : ${data.data.data.content} \n Fluency Score : ${data.data.data.fluency} \n Pronunciation Score : ${data.data.data.pronunciation} \n ${data.data.data.html}`,
                });
              }
            })
            .catch(() => {});
        } else if ([6, 7].includes(sub_id)) {
          var bodyFormData = new FormData();
          bodyFormData.set("text", this.state.resultObj.answer);
          bodyFormData.set("text_answer", this.state.resultObj.text_answer);
          bodyFormData.set("type", this.state.resultObj.type);
          bodyFormData.set("answer", this.state.resultObj.q_ans);
          bodyFormData.set("script", this.state.resultObj.script);
          bodyFormData.set("length", this.state.resultObj.length);
          bodyFormData.set("id", this.state.resultObj.id);
          bodyFormData.set("practice", "1");
          bodyFormData.set("lang", this.state.resultObj.lang);

          API({
            method: "POST",
            url: "check/answer1",
            data: bodyFormData,
          })
            .then((data) => {
              if (data.data.user) {
                store.dispatch(setUser(data.data.user));
                this.loadAttemptedQuestiondata(this.state.currentQuestion);
              }
              if (this.state.questionData[0].subcategory_id === 6) {
                this.setState({
                  user: data.data.user,
                  process: false,
                  historyData: data.data.data.score_data,
                  resultData: `Content : ${data.data.data.content_score}/2 \n Form : ${data.data.data.form_score}/1 \n Grammar and spellings : ${data.data.data.grammar_score}/2 \n Vocabulary : ${data.data.data.vocab_score}/2 \n Total : ${data.data.data.total_s}/7 \n ${data.data.data.error}`,
                });
              } else {
                this.setState({
                  user: data.data.user,
                  process: false,
                  historyData: data.data.data.score_data,
                  resultData: `Content : ${data.data.data.content_score}/3 \n Form : ${data.data.data.form_score}/2 \n Grammar : ${data.data.data.grammar_score}/2 \n Vocabulary : ${data.data.data.vocab_score}/2 \n Linguistic range : ${data.data.data.ling_score}/2 \n Structure : ${data.data.data.struct_score}/2 \n Spelling : ${data.data.data.spelling_score}/2 \n Total : ${data.data.data.total_s}/15 \n ${data.data.data.error}`,
                });
              }
            })
            .catch(() => {});
        } else if ([8, 9, 10, 11, 12].includes(sub_id)) {
          var bodyFormData = new FormData();
          bodyFormData.set("text", this.state.resultObj.answer);
          bodyFormData.set("selected", this.state.resultObj.text_answer);
          bodyFormData.set("type", this.state.resultObj.type);
          bodyFormData.set("answer", this.state.resultObj.correct);
          bodyFormData.set("script", this.state.resultObj.script);
          bodyFormData.set("correct", this.state.resultObj.correct);
          bodyFormData.set("id", this.state.resultObj.id);
          bodyFormData.set("practice", "1");
          bodyFormData.set("lang", this.state.resultObj.lang);
          API({
            method: "POST",
            url: "check/answer1",
            data: bodyFormData,
          })
            .then((data) => {
              if (data.data.user) {
                store.dispatch(setUser(data.data.user));
                this.loadAttemptedQuestiondata(this.state.currentQuestion);
              }
              this.setState({
                user: data.data.user,
                process: false,
                historyData: data.data.data.score_data,
                resultData: `Score : ${data.data.data.score}/${data.data.data.from}`,
              });
            })
            .catch(() => {});
        } else if ([13, 14, 15, 17, 19, 20, 18, 16].includes(sub_id)) {
          var bodyFormData = new FormData();
          bodyFormData.set("text", this.state.resultObj.answer);
          bodyFormData.set("selected", this.state.resultObj.text_answer);
          bodyFormData.set("html", this.state.resultObj.html);
          bodyFormData.set("type", this.state.resultObj.type);
          bodyFormData.set("text_answer", this.state.resultObj.text_answer);
          bodyFormData.set("ans", this.state.resultObj.q_ans);
          bodyFormData.set("answer", this.state.resultObj.correct);
          bodyFormData.set("script", this.state.resultObj.script);
          bodyFormData.set("correct", this.state.resultObj.correct);
          bodyFormData.set("length", this.state.resultObj.length);
          bodyFormData.set("id", this.state.resultObj.id);
          bodyFormData.set("practice", "1");
          bodyFormData.set("lang", this.state.resultObj.lang);
          API({
            method: "POST",
            url: "check/answer1",
            data: bodyFormData,
          })
            .then((data) => {
              if (data.data.user) {
                store.dispatch(setUser(data.data.user));
                this.loadAttemptedQuestiondata(this.state.currentQuestion);
              }

              if (this.state.questionData[0].subcategory_id === 13) {
                this.setState({
                  user: data.data.user,
                  process: false,
                  historyData: data.data.data.score_data,
                  resultData: `Content : ${data.data.data.content_score}/2 \n Form : ${data.data.data.form_score}/2 \n Grammar : ${data.data.data.grammar_score}/2 \n Vocabulary : ${data.data.data.vocab_score}/2 \n Spelling : ${data.data.data.spelling_score}/2 \n Total : ${data.data.data.total_s}/10 \n ${data.data.data.error}`,
                });
              } else if (this.state.questionData[0].subcategory_id === 20) {
                this.setState({
                  user: data.data.user,
                  process: false,
                  historyData: data.data.data.score_data,
                  resultData: `Score : ${data.data.data.score}/${data.data.data.from} \n ${data.data.data.html}`,
                });
              } else {
                this.setState({
                  user: data.data.user,
                  process: false,
                  historyData: data.data.data.score_data,
                  resultData: `Score : ${data.data.data.score}/${data.data.data.from}`,
                });
              }
            })
            .catch(() => {});
        }
      }
    );
  };

  resultStatus = () => {
    this.setState({ is_result: true });
  };

  showHistory = (e) => {
    e.preventDefault();
    this.setState({
      boardMore: false,
      history_flag: true,
      note_flag: false,
      comment_flag: false,
      board_flag: false,
      history_process: false,
    });
  };

  showBoard = (e) => {
    e.preventDefault();
    this.setState({
      historyMore: false,
      note_flag: false,
      history_flag: false,
      board_flag: true,
      comment_flag: false,
      board_process: false,
    });
  };

  showNotes = (e) => {
    e.preventDefault();
    this.setState({
      historyMore: false,
      history_flag: false,
      board_flag: false,
      note_flag: true,
      comment_flag: false,
      board_process: false,
    });
  };

  showComment = (e) => {
    e.preventDefault();
    this.setState({
      comment_flag: true,
      history_flag: false,
      board_flag: false,
      note_flag: false,
      comment_process: false,
    });
  };

  changeResponseArr = (data) => {
    this.setState({ history: data });
  };

  testedFunc = () => {
    this.setState({ testModal: true });
  };

  toggleSwitch = (el, state) => {
    this.setState({ switched: !this.state.switched });
  };

  testedNo = () => {
    this.setState({ testModal: false });
  };

  testedYes = () => {
    var bodyFormData = new FormData();
    bodyFormData.set("question_id", this.state.questionData[0].id);
    API({
      method: "POST",
      url: "tested/exam",
      data: bodyFormData,
    }).then((data) => {
      store.dispatch(
        setAlert({
          flag: true,
          type: 1,
          message: data.data.message,
        })
      );
      this.setState({
        testModal: false,
        testCount: data.data.count,
      });
    });
    this.setState({ testModal: false });
    this.forceUpdate();
  };

  // timer1 = () => {
  //   this.setState({ secondCount1: this.state.secondCount1 +1 });
  // }

  restartQuestion = () => {
    this.setState({
      restart: true,
      is_result: false,
      is_answer: false,
      submitFlag: false,
      secondCount1: 0,
    });
    this.forceUpdate();
  };

  showVideoModal = () => {
    this.setState({ videoModal: true });
    this.setState({ videoModalState: true });
    console.log(this.state);
  };

  showHindiVideoModal = () => {
    this.setState({ videoHindiModal: true });
    this.setState({ videoHindiModalState: true });
  };

  closeVideoModal = () => {
    this.setState({ videoModal: false });
    // this.setState({videoModalState:false});
  };

  closeHindiVideoModal = () => {
    this.setState({ videoHindiModal: false });
    // this.setState({videoModalState:false});
  };

  restartQue = () => {
    this.setState({ restart: false, secondCount1: 0 });
  };

  changeInput = (e) => {
    let { name, value } = e.target;
    var bodyFormData = new FormData();
    bodyFormData.set("question_id", this.state.questionData[0].id);
    bodyFormData.set("tag", value);

    bodyFormData.set("id", this.state.type);
    bodyFormData.set("prediction", this.state.prediction);
    bodyFormData.set("type", this.state.aType);
    bodyFormData.set("mark", this.state.mark);
    API({
      method: "POST",
      url: "set/tag",
      data: bodyFormData,
    }).then((data) => {
      let items = this.state.questionData;

      items[0] = { ...this.state.questionData[0], tag: [{ tag: value }] };
      this.setState({ questionData: items });
    });
    this.setState({ [name]: value });
  };

  soundChecked = () => {};
  stopRecording = () => {};

  setRestart = () => {
    this.setState({ restart: false });
  };

  setNewQuestion = () => {
    this.setState({ newQuestion: false });
  };

  setStopTimer = () => {
    this.setState({ stopTimer: false });
  };
  getFormattedMediaLink = (mediaLink) => {
    if (!mediaLink) return '';
      return s3base + (mediaLink.startsWith('/') ? mediaLink : `/${mediaLink}`);
  };

  setStrategy = (st) => {
    const r = this.state.resultObj;
    r.strategy = st;
    this.setState({
      resultObj: r,
    });
  };

  render() {
    let que;
    let ques_title = "";
    let title;
    let topBar = false;
    let props = {
      restart: this.state.restart,
      restartQue: this.restartQue,
      submit: this.submitResponse,
      showResult: this.resultStatus,
      data: this.state.questionData[0],
      func: this.changeAnswer,
      answer: this.state.is_answer,
      resultMode: false,
      testCount: 0,
      tag: "grey",
      testMode: false,
      resultId: false,
      soundChecked: this.soundChecked,
      stopRecording: this.state.stopRecording,
      newQuestion: this.state.newQuestion,
      setNewQuestion: this.setNewQuestion,
      strategy: this.state.resultObj.strategy,
      setStrategy: this.setStrategy,
      restartQuestion: this.restartQuestion,
    };

    let {
      questionData,
      currentQuestion,
      type,
      all_ok,
      modal,
      reportText,
      testModal,
      videoModal,
      videoModalState,
      videoUrl,
      videoHindiModal,
      videoHindiModalState,
      videoHindiUrl,
      is_answer,
      tag,
      timeDiffInMs,
      thirtyDaysInMs,
      isAttempted,
      secondCount1,
      hideNav,
      total_questions,
      isBlocked,
      resultData,
      historyData,
      resultId,
      switched,
      isSubscribed,
      user,
      submitFlag,
      viewVideo,
      viewHindiVideo,
      testCount,
      comment_flag,
      isSubscribedCheck,
      history_flag,
      board_flag,
      note_flag,
      open_ques,
      freeze,
      selectedWord,
      wordOption
      // buttonText,
    } = this.state;

    if (questionData.length > 0) {
      ques_title = questionData[0].q_title;
    }

    switch (type) {
      case "1":
        que = <ReadAloud {...props} key={currentQuestion} />;
        title =
          "Look at the text below. In the 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 35 seconds to read aloud.";
        break;
      case "2":
        que = <RepeatSentece {...props} key={currentQuestion} />;
        title =
          "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.";
        break;
      case "3":
        que = <DescribeImage {...props} key={currentQuestion} />;
        title =
          "Look at the Image below. In 25 seconds,please speak into the microphone and describe in detail what the graph showing. You will have 40 seconds to give your response.";
        break;
      case "4":
        que = <RetellLec {...props} key={currentQuestion} />;
        title =
          "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.";
        break;
      case "5":
        que = <ShortQue {...props} key={currentQuestion} />;
        title =
          "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.";
        break;
      case "6":
        que = <Summary {...props} key={currentQuestion} />;
        title =
          "Read the passage and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. your response will be judge on the quality of your writing and on how well your response presents the key points in the passage.";
        break;
      case "7":
        que = <Essay {...props} key={currentQuestion} />;
        title =
          "You will have 20 minutes to plan, write and revise an essay about the topic below. your response will be judged on how well you develop a position, oraganize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.";
        break;
      case "8":
        topBar = true;
        que = <SingleAnswer {...props} key={currentQuestion} />;
        title =
          "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
        break;
      case "9":
        topBar = true;
        que = <MultipleAnswer {...props} key={currentQuestion} />;
        title =
          "Read the text and answer the question by selecting all the correct responses. More than one response is correct.";
        break;
      case "10":
        que = <Reorder {...props} key={currentQuestion} />;
        title =
          "The textbox in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.";
        break;
      case "11":
        topBar = true;
        que = <ReadingBlank {...props} key={currentQuestion} />;
        title =
          "In the text below some words are missing. Drag words from the text box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.";
        break;
      case "12":
        topBar = true;
        que = <WrittingBlank {...props} key={currentQuestion} />;
        title =
          "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.";
        break;
      case "13":
        que = <Summer {...props} key={currentQuestion} />;
        title =
          "You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should wirte 50-70 words. You have 10 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.";
        break;
      case "14":
        topBar = true;
        que = <McqSingle {...props} key={currentQuestion} />;
        title =
          "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
        break;
      case "15":
        topBar = true;
        que = <McqMulti {...props} key={currentQuestion} />;
        title =
          "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.";
        break;
      case "16":
        topBar = true;
        que = <ListeningBlank {...props} key={currentQuestion} />;
        title =
          "You will hear a recording. Type the missing words in each blank.";
        break;
      case "17":
        topBar = true;
        que = <CorrectSummary {...props} key={currentQuestion} />;
        title =
          "You will hear a recording. Click on the paragraph that best relates to the recording.";
        break;
      case "18":
        topBar = true;
        que = <MissingWord {...props} key={currentQuestion} />;
        title =
          "You will hear a recording about " +
          ques_title +
          ". At the end of the recording the last word or group of words has benn replaced by a beep. Select the correct option to complete the recording";
        break;
      case "19":
        topBar = true;
        que = <Highlight {...props} key={currentQuestion} />;
        title =
          "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.";
        break;
      case "20":
        que = <Dictation {...props} key={currentQuestion} />;
        title =
          "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.";
        break;
      default:
      // code block
    }

    /*
     * Timer settings.
     */
    let timerSettings = {
      secondCount: 0,
      setSecondCount: this.setSecondCount,
      setIntervalId: this.setIntervalId,
      stopTimer: this.state.stopTimer,
      setStopTimer: this.setStopTimer,
      restart: this.state.restart,
      setRestart: this.setRestart,
      newQuestion: this.state.newQuestion,
      setNewQuestion: this.setNewQuestion,
    };

    return (
      <>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>

        {!all_ok && <ChromeCheck />}
        {all_ok && (
          <div className="content questionsArea">
            <CheckInternetTimer />
            {modal && (
              <Modal isOpen={this.state.modal} size="lg">
                <ModalBody>
                  <ModalHeader>
                    Report the problem in this question{" "}
                  </ModalHeader>
                  <Input
                    type="textarea"
                    value={reportText}
                    name="reportText"
                    onChange={(e) => {
                      this.changeReportText(e);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    className="modalCloseBtn"
                    onClick={this.submitReport}
                  >
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                    className="modalCloseBtn"
                    onClick={this.showModal}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}

            <Modal isOpen={testModal} size="lg">
              <ModalHeader>Do you have seen this question in exam </ModalHeader>
              <ModalFooter>
                <Button
                  color="secondary"
                  className="modalCloseBtn"
                  onClick={this.testedYes}
                >
                  Yes
                </Button>
                <Button
                  color="secondary"
                  className="modalCloseBtn"
                  onClick={this.testedNo}
                >
                  No
                </Button>
              </ModalFooter>
            </Modal>

            {videoModal && (
              <Modal
                className="videopopup"
                centered="true"
                isOpen={videoModalState}
                size="lg"
              >
                <ModalBody>
                  <ReactPlayer url={videoUrl} controls={true} />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    className="modalCloseBtn"
                    onClick={this.closeVideoModal}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}

            {videoHindiModal && (
              <Modal
                className="videopopup"
                centered="true"
                isOpen={videoHindiModalState}
                size="lg"
              >
                <ModalBody>
                  <ReactPlayer url={videoHindiUrl} controls={true} />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    className="modalCloseBtn"
                    onClick={this.closeHindiVideoModal}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            )}

            <Row>
              <Col xs={12}>
                <Card>
                  {this.state.process && (
                    <div className="scoreLoader text-center showLoader">
                      <span>AI Scoring Ongoing ...</span>
                    </div>
                  )}

                  <CardHeader>
                    {topBar && is_answer && (
                      <div className="row infoBars">
                        <div className="col-sm-3 wrongAns">
                          <i className="fa fa-times" aria-hidden="true"></i>{" "}
                          <span>Your selected & wrong answer</span>
                        </div>
                        <div className="col-sm-3 correctAns">
                          <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          <span>Your selected & correct answer</span>
                        </div>
                        <div className="col-sm-3 notSelected">
                          <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          <span>Not selected & correct answer</span>
                        </div>
                      </div>
                    )}

                    <Row>
                      <Col xs={6}>
                        <CardTitle tag="h3">
                          {questionData.length > 0 && questionData[0].q_title}
                          {questionData.length > 0 && (
                            <select
                              name="tag"
                              onChange={(e) => {
                                this.changeInput(e);
                              }}
                              style={{
                                border: "0px",
                                fontFamily: "FontAwesome",
                                background: "white",
                                color: tag,
                              }}
                            >
                              <option value="grey" style={{ color: "grey" }}>
                                &#xf02c;
                              </option>
                              <option value="red" style={{ color: "red" }}>
                                &#xf02c;
                              </option>
                              <option value="green" style={{ color: "green" }}>
                                &#xf02c;
                              </option>
                              <option
                                value="yellow"
                                style={{ color: "yellow" }}
                              >
                                &#xf02c;
                              </option>
                            </select>
                          )}
                        </CardTitle>

                        {questionData.length > 0 && questionData[0].is_weekly_prediction === 1 &&
                          <Badge color="primary">Monthly Prediction</Badge>
                        }

                        {questionData.length > 0 &&
                          questionData[0].is_exam_question === 1 && (
                            <Badge color="primary">Exam Question</Badge>
                          )}

                        {questionData.length > 0 &&
                          questionData[0].complexity === 0 && (
                            <Badge color="primary">Easy</Badge>
                          )}

                        {questionData.length > 0 &&
                          questionData[0].complexity === 1 && (
                            <Badge color="primary">Medium</Badge>
                          )}

                        {questionData.length > 0 &&
                          questionData[0].complexity === 2 && (
                            <Badge color="primary">Difficult</Badge>
                          )}

                        {isAttempted && (
                          <Badge color="success">Attempted</Badge>
                        )}
                      </Col>

                      <Col xs={6}>
                        <div className="text-right questionTime">
                          <Timer {...timerSettings} />
                          <div className="item">
                            {!hideNav && (
                              <p>
                                Item {currentQuestion} of {total_questions}
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="instruction-label">{title}</div>
                  </CardHeader>

                  <CardBody>
                    {questionData.length === 0 && (
                      <h5 className="text-center">No Question Found</h5>
                    )}

                    <Row className="unselectable">
                      {!isBlocked &&
                        questionData.length !== 0 &&
                        store.getState().loaded &&
                        questionData && (
                          <>
                            {que}
                            {/*<KeepAlive />*/}
                          </>
                        )}
                      {isBlocked && (
                        <h2>Please attach microphone to your system </h2>
                      )}

                      {is_answer && resultData !== "" && (
                        <Col xs={12}>
                          <div className="result-div">
                            {resultData.split("\n").map((item, i) => {
                              return <h4>{ReactHtmlParser(item)}</h4>;
                            })}

                            {(type === "3" || type === "4") && (
                              <div>
                                <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW">
                                  <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                    <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd"></div>
                                    Good
                                  </div>
                                  <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                    <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJav"></div>
                                    Average
                                  </div>
                                </div>
                              </div>
                            )}

                            {(type === "1" || type === "2") && (
                              <div>
                                <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW">
                                  <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                    <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd"></div>
                                    Good
                                  </div>
                                  <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                                    <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJbad"></div>
                                    Bad pronunciation/Missed words
                                  </div>
                                  <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca"></div>
                                </div>
                              </div>
                            )}

                            {/*<ShowHistory data={this.state.history} flag={1} id={this.state.resultId} />*/}

                            <ShowHistoryData
                              data={this.state.historyData}
                              flag={1}
                              id={this.state.resultId}
                            />
                            {type === "5" && (
                              <h3>
                                Correct Answer -{" "}
                                <span className="green-text">
                                  {questionData[0].answer}
                                </span>
                              </h3>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>

                    {switched && (
                      <span className="unselectable">
                        <h3>Question Audio</h3>
                        <audio
                          src={this.getFormattedMediaLink(questionData[0].media_link)}
                          controls="controls"
                        />

                        <h3>Audio Script</h3>
                        <div className="audio_script_text">
                          {questionData[0].audio_script
                            .split(" ")
                            .map((word, i) => (
                              <span
                                className={
                                  this.props.testMode ? "" : "speach-text"
                                }
                                key={i}
                                // onClick={() => this.handleSpanClick(item)}
                                onClick={() => this.wordOptionPopup(true, word)}
                              >
                                {word}{" "}
                              </span>
                            ))}
                        </div>
                      </span>
                    )}
                  </CardBody>
                  {wordOption &&
            <WordOptionsPopup
              isOpen={wordOption}
              word={selectedWord}
              wordOptionAction={this.wordOptionPopup}
            />
          }
                </Card>
              </Col>

              <Col xs={12}>
                {false && !isSubscribed && (
                  <span className="badge badge-primary">
                    (A.I. Token(s) X {user.a_token})
                  </span>
                )}

                {!isBlocked && questionData.length !== 0 && (
                  <div className="bottomBtns">
                    <div className="row">
                      <Col xs={8}>
                        {false &&
                          !isSubscribed &&
                          user.a_token > 0 &&
                          !this.state.process && (
                            <Button
                              color="primary"
                              className="bottom-btn"
                              disabled={is_answer || !submitFlag}
                              onClick={this.showResponse}
                              size="sm"
                            >
                              Submit
                            </Button>
                          )}

                        {!this.state.process && (
                          <Button
                            color="primary"
                            className="bottom-btn"
                            disabled={is_answer || !submitFlag}
                            onClick={this.showResponse}
                            size="sm"
                          >
                            Submit
                          </Button>
                        )}
                        {false &&
                          !isSubscribed &&
                          user.a_token <= 0 &&
                          !this.state.process && (
                            <Link to="/video-courses">
                              <Button
                                color="primary"
                                className="bottom-btn"
                                size="sm"
                              >
                                Subscribe to paid plan for submit answer
                              </Button>
                            </Link>
                          )}
                        {this.state.process && (
                          <Button
                            color="primary"
                            className="bottom-btn"
                            size="sm"
                          >
                            <i className="fa fa-spinner"></i>&nbsp;Submit
                          </Button>
                        )}
                        {
                          <Button
                            color="info"
                            className="bottom-btn"
                            onClick={this.restartQuestion}
                            disabled={false}
                            size="sm"
                          >
                            Restart
                          </Button>
                        }
                        {viewVideo ? (
                          <Button
                            color="info"
                            className="bottom-btn"
                            onClick={this.showVideoModal}
                            disabled={false}
                            size="sm"
                          >
                            Explanation (English)
                          </Button>
                        ) : (
                          ""
                        )}
                        {viewHindiVideo ? (
                          <Button
                            color="info"
                            className="bottom-btn"
                            onClick={this.showHindiVideoModal}
                            disabled={false}
                            size="sm"
                          >
                            Explanation (Hindi)
                          </Button>
                        ) : (
                          ""
                        )}
                        {[4, 2, 5, 13, 14, 15, 16, 17, 18, 19, 20].includes(
                          questionData[0].subcategory_id
                        ) && (
                          <span className="audio_script_button">
                            Audio Transcript :{" "}
                            <Switch
                              onText="Show"
                              offText="Hide"
                              onChange={(el, state) =>
                                this.toggleSwitch(el, state)
                              }
                              on={this.state.switched}
                            ></Switch>
                          </span>
                        )}
                      </Col>

                      {!hideNav && (
                        <Col xs={4} className="text-right">
                          <select
                            className="selectPage"
                            onChange={this.changeSelectPagination}
                          >
                            {Array.from(
                              { length: total_questions },
                              (_, key) => (
                                <option key={key} value={key + 1}>
                                  {key + 1}
                                </option>
                              )
                            )}
                          </select>

                          {questionData.length !== 0 && (
                            <Button
                              color="info"
                              className="bottom-btn"
                              onClick={this.prevButton}
                              disabled={currentQuestion === 1}
                              size="sm"
                            >
                              Previous
                            </Button>
                          )}
                          {questionData.length !== 0 && (
                            <Button
                              color="info"
                              className="bottom-btn"
                              onClick={this.nextButton}
                              disabled={total_questions === currentQuestion}
                              size="sm"
                            >
                              Next
                            </Button>
                          )}
                        </Col>
                      )}
                      {this.state.hideNav && <Col xs={4}></Col>}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={12}>
                <div>
                  <>
                    <div>
                      <>
                        <div className="bottomBtns">
                          {questionData.length !== 0 && (
                            <Button
                              color="info"
                              className="bottom-btn"
                              size="sm"
                            >
                              {questionData[0].attempted.length} x Attempted
                            </Button>
                          )}
                          {questionData.length !== 0 && (
                            <Button
                              color="info"
                              onClick={this.testedFunc}
                              className="bottom-btn"
                              size="sm"
                            >
                              {testCount} x tested
                            </Button>
                          )}
                          {questionData.length !== 0 && (
                            <Button
                              color="info"
                              style={{ float: "right" }}
                              className="bottom-btn"
                              onClick={this.showModal}
                              disabled={false}
                              size="sm"
                            >
                              Report
                            </Button>
                          )}
                        </div>
                      </>
                      <Row className="discussion row">
                        <div className="discuTabs">
                          <p className="mt-1 mb-2 text-data-warning">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            {this.state.isSubscribedCheck === true
                              ? " Data available for the last 3 months."
                              : " Data available for the last 1 month."}
                          </p>
                          <ul className="nav nav-tabs">
                            <li className={comment_flag ? "active" : ""}>
                              {questionData.length !== 0 && (
                                <a href="#" onClick={this.showComment}>
                                  <i
                                    className="fa fa-commenting-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  &nbsp;Discussion
                                </a>
                              )}
                            </li>
                            <li className={history_flag ? "active" : ""}>
                              {questionData.length !== 0 && (
                                <a href="#" onClick={this.showHistory}>
                                  <i
                                    className="fa fa-list-ul"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  &nbsp;Me
                                </a>
                              )}
                            </li>
                            <li className={board_flag ? "active" : ""}>
                              {questionData.length !== 0 && (
                                <a href="#" onClick={this.showBoard}>
                                  <i
                                    className="fa fa-list-ul"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  &nbsp;Others
                                </a>
                              )}
                            </li>
                            <li className={note_flag ? "active" : ""}>
                              {questionData.length !== 0 && (
                                <a href="#" onClick={this.showNotes}>
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  &nbsp;Notes
                                </a>
                              )}
                            </li>
                          </ul>
                        </div>
                      </Row>
                    </div>
                  </>
                </div>
              </Col>
              <Col xs={12} className="practiceContent">
                <div>
                  {note_flag && <UserNotes question={questionData[0].id} />}
                  {history_flag && (
                    <ShowHistory
                      all="0"
                      changeResponseArr={this.changeResponseArr}
                      question={questionData[0].id}
                      flag={0}
                    />
                  )}
                  {board_flag && (
                    <ShowHistory
                      all="1"
                      changeResponseArr={this.changeResponseArr}
                      question={questionData[0].id}
                      flag={0}
                    />
                  )}
                  {comment_flag && (
                    <QuestionComment question={questionData[0].id} />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Practice);
