import React from 'react';
import {
	Col
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import ShowHistoryData from "views/ShowHistoryData.jsx";

export default function PracticeResult({
	resultData,
	type,
	historyData,
	response
}) {

	const showResponse = () => {
		if (type === '1' || type === '2'||type === '3'||type === '4') {
			return (
				<div>
				<h4> {response}</h4>
					<div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW" >
						<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
							<div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd" ></div>Good
						</div>
						<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
							<div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJbad"></div>
							Bad pronunciation/Missed words
						</div>
						<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca"></div>
					</div>
				</div>
			);
		}
	}

	return (
		<Col xs={12}>
			<div className="result-div">
				{resultData.split('\n').map((item, i) => {
					return <h4 key={i}>{ReactHtmlParser(item)}</h4>;
				})}

				{showResponse()}

				<ShowHistoryData
					data={historyData}
					flag={1}
					id={false}
				/>
			</div>
		</Col>
	);
}